
 

.home-header{
  height: 7rem;
}

#logo{
  margin-bottom: 0px;
}
.logo{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.logo img {
  max-width: 100%;
  height:5rem;
}
.logo-text {
  color:  #0473ea;
  margin-bottom: 0px;
}

.login {
  width: 20%;
  display: flex;
  align-items: center;
}
.login > p {
  width: 75%;
  margin-right: 1rem;
}





#main-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80vh;

}

#main-section-short{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 65vh;
  box-shadow: 0px 4px 8px rgba(157, 157, 157, 0.078);
  margin-bottom: 2rem;
}
  .main-section-text{
    margin-top: 2.5rem;
    width: 40%;
  }
  .main-section-text> h1 {
    font-size: 3.3rem;
  }
  .main-section-text> h1 > span{
    font-size: 3.3rem;
    background: linear-gradient(315deg, #a19dd8 0%, var(--blue) 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .main-section-text> p{
    font-size: 1.23rem;
    margin-top: 2.5rem;
  }
  .main-section-text {
    width: 50%;
    margin: 3rem;
  }

  .main-button > *{
    margin-left: 2rem;
    margin-top: 2rem;
  }

  .hero-image{
    height:25rem;
  }

  
  section {
    padding: 80px 0;
    text-align: center;
  }
  
  section h2 {
    margin-bottom: 40px;
  }
  

#frase1{
  background-color: #f4f4f4;
}

  .frase1 {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
    margin-top: 7rem;
  }
  .frase1 > h2 {
    font-size: 2.5rem;
    color: #0473ea;
    font-weight: bolder;
  }
  .frase1 > p{
    font-size: 1.6rem;
    margin-top: 2.5rem;
  }


  .users {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
  }
  .users > h2 {
    font-size: 2.5rem;
  }
  .users > p{
    font-size: 1.3rem;
    margin-top: 2.5rem;
  }
  #users > button{
    width: 20%;
  }

  .users-image{
    width:  20vh;
    height: auto;
    object-fit: cover; 
  }

  .footer {
    background-color: #0473ea;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }


  .footer-contact{
    color: white;
  }

  .footer-section {
      margin-bottom: 20px;
  }

  .footer-bottom {
      margin-top: 20px;
  }


  
@media only screen and (max-width: 820px) {

  #main-section{
    display: flex;
    flex-direction: column; /* Asegura que los elementos se apilen verticalmente */
    margin-top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto; /* Permite el desplazamiento si el contenido se desborda */
  }
  .main-section-text{
    margin-top: 2.5rem;
    width: 90%;
  }
  .main-section-text> h1 {
    font-size: 2.5rem;
  }
  .main-section-text> h1 > span{
    font-size: 2.5rem;
    background: linear-gradient(315deg, #a19dd8 0%, var(--blue) 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .main-button > *{
    margin-left: 2rem;
    margin-top: 1.5rem;
  } 
  .hero-image{
    height:50vw;
  }
}