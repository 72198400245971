

body {
    background-color: #fefefe;
}

.checkout-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.summary-section {
    margin-bottom: 3rem;
}

.summary-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #222;
}

.summary-details p {
    font-size: 16px;

}

.checkout-title {
    font-size: 1.3rem;
    margin-bottom: 2rem;
    font-weight: 600;
    color: #222;
}

.checkout-form {
    max-width: 400px;
    margin: 0 auto;
}
.form-group{
    margin-bottom: 2rem;
}
.form-group label {
    font-weight: bold;
    color: #333;
    display: block;
    margin-bottom: 3px;
    position: relative;
}

.form-group input, .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: none;
    border-bottom: 2px solid #ccc;
    outline: none;
    transition: border-color 0.3s ease;
}

.form-group input:focus, .form-group textarea:focus {
    border-bottom: 2px solid #000;
}

.form-group input:hover, .form-group textarea:hover {
    border-bottom: 2px solid #666;
}

.form-group input::placeholder, .form-group textarea::placeholder {
    font-size: 12px;
    color: #999;
}

.checkout-button {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.checkout-button:hover {
    background-color: #333;
}

.button-payment {
    background-color: #007BFF; /* Azul moderno */
    color: white; /* Texto en blanco */
    border: none; /* Sin borde */
    padding: 12px 24px; /* Espaciado interno */
    border-radius: 50px; /* Bordes redondeados */
    font-size: 16px; /* Tamaño del texto */
    font-weight: bold; /* Texto en negrita */
    cursor: pointer; /* Puntero de clic */
    transition: background-color 0.3s, transform 0.2s; /* Transición suave */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra ligera */
  }
  
  .button-payment :hover {
    background-color: #0056b3; /* Color más oscuro en hover */
    transform: scale(1.05); /* Ligeramente más grande al pasar el mouse */
  }
  
  .button-payment :active {
    background-color: #004080; /* Color aún más oscuro cuando se hace clic */
    transform: scale(0.98); /* Ligeramente más pequeño al hacer clic */
  }
  
  .button-payment :focus {
    outline: none; /* Quitar borde de enfoque */
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Sombra de enfoque accesible */
  }
  


@media (max-width: 768px) {
    .checkout-container {
        padding: 15px;
    }

    .summary-title,
    .checkout-title {
        font-size: 22px;
    }

    .checkout-button {
        font-size: 14px;
    }
}
