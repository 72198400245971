.event-detail-page{
  display: flex;
  flex-direction: column; /* Stack the divs on top of each other */
  align-items: center; /* Center the first div horizontally */
}

.event-detail {
    max-width: 800px;
    margin: 2rem 10px;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .event-detail h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .event-detail p {
    font-size: 1.1rem;
    margin-bottom: 15px;
  }
  
  .event-detail img {
    width: 100%;
    max-height: 800px;
    object-fit: contain;
    border-radius: 8px;
    margin-bottom: 20px;
    margin: 1.5rem 0 3rem 0;
  }
  .how-it-works {
    margin-top: 3rem;
    width: 100%;
  }
  .how-it-works > div {
    width: 90% ;
}






/* COMPONENT: SEAT SELECTOR*/
.seat-selector {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 5rem;
  background-color: #ffffff;
  border-radius: 14px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Adds shadow effect */

}

.seat-selector .form-group {
  margin-bottom: 20px;
}
.seat-selector h2{
  font-size: 1.7rem;
  margin-bottom: 1.7rem;
}
.seat-selector label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.25rem;
  color: black;
  
}

.seat-selector select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  transition: border-color 0.3s ease;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
}

.seat-selector select:focus {
  border-bottom: 2px solid #000;
}

.seat-selector select:hover {
  border-bottom: 2px solid #666;
}

.seat-selector .price-display {
  font-size: 16px;
  color: #000;
  margin-top: 2rem;
}

.seat-selector .price-display strong {
  font-weight: bold;
  color: #333;
}

.seat-selector > .type-of-trip {
  margin-top: 10px;
  font-size: 13px;
}



.buy-button {
  background-image: linear-gradient(to right, var(--blue), var(--blue));
  color: white;
  font-weight: bold;
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.02em;
  margin-top: 1rem;
}

.buy-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  background-image: linear-gradient(to right, #251d91, #251d91);
}

.buy-button:active {
  transform: translateY(3px);
}



@media (max-width: 1000px) {
    .event-detail {
        padding: 2rem;
        max-width: 600px;
    }

    .event-detail h1 {
        font-size: 1.8rem;
    }

    .event-detail p {
        font-size: 1rem;
    }

    .event-detail img {
        max-height: 300px;
    }
    
}