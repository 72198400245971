

.parent-container-event {
    display: flex;
    justify-content: center; /* Centers the child horizontally */
    align-items: center; /* Centers the child vertically */
    padding: 20px;
    margin-top: 1rem;
}
.event-list {
    padding: 20px;
    width: 80%;
    min-height: 70vh ;
    background-color: #f5f5f5;
}

.event-grid {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of boxes */
    gap: 30px; /* Space between each box */
    justify-content: center; /* Centers the boxes */
}

.event-item {
    flex: 1 1 calc(33.333% - 20px); /* Responsive columns (3 per row) */
    max-width: calc(33.333% - 20px); /* Ensures max width of each box */
    box-sizing: border-box; /* Includes padding and border in width */
    list-style-type: none;
    
}

.eventos {
    display: flex;
    flex-direction: column;
    background-color: #e7e7e7;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%; /* Ensures equal height of boxes */
}

.event-img-container {
    width: 100%;
    height: 350px; /* Fixed height for the image */
    overflow: hidden; /* Crop the image if it's too large */
}

.event-img-container img {
    width: 100%;
    height: 100%;
    min-height: 450px;
    object-fit: cover; /* Ensures the image covers the container, cropping if necessary */
}

.event-info {
    padding: 20px;
    text-align: left;
    flex-grow: 1; /* Ensures the info section takes up available space */
}

.event-info h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.event-info p {
    margin: 5px 0;
    color: #555;
}

.event-info .event-icon {
    margin-right: 5px;
    color: #666;
    font-size: 1.2em;
}

/* Responsive Design */
@media (max-width: 1000px) {

    .event-list {
        width: 90%;
    }
    .event-item {
        flex: 1 1 calc(50% - 20px); /* Two columns per row on tablet */
        max-width: calc(50% - 20px);
    }
}

@media (max-width: 820px) {
    .event-list {
        width: 90%;
    }
    .event-item {
        flex: 1 1 100%; /* Full width on small screens */
        max-width: 100%;
    }
}
